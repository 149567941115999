<template>
  <div class="main-inner-blk">
    <div class="admin-container">
      <div class="admin-content">
        <AdminList :adminList="adminList" @on-click="onAdminClick" @on-remove="getAdminList"></AdminList>

        <AdminInsert @on-submit="onAdminSubmit" :admin="admin" @on-clear="onClear" :status="status"></AdminInsert>
      </div>
    </div>
    <Loading v-if="loading"></Loading>
    <confirm ref="confirm"></confirm>
  </div>
</template>

<script>
import AdminList from "@/components/admin/AdminList";
import AdminInsert from "@/components/admin/AdminInsert";
import axios from "@/axios/axios-global";
import Loading from "@/components/common/Loading";
import Confirm from "@/components/common/Confirm";

export default {
  name: "adminDashboard",
  components: {Confirm, Loading, AdminInsert, AdminList},
  data: () => {
    return {
      loading: false,
      adminList: [],
      search: '',
      admin: {
        name: null,
        mobile: null,
        email: null,
      },
      status: 'insert'
    }
  },
  mounted() {
    this.getAdminList()
  },
  methods: {
    onAdminSubmit(data) {
      if (data.status === 'insert') {
        this.insertAdmin(data.admin)
      } else {
        this.editAdmin(data.admin)
      }
    },
    async insertAdmin(payload) {
      let confirmTitle = "Confirm Insert";
      let confirmText = "Please confirm to Insert a new Admin";
      if (await this.$refs.confirm.open(confirmTitle, confirmText)) {
        this.loading = true
        try {
          const response = await axios.post('/router/addAdmin', payload)
          console.log(response)
          this.loading = false
          if (response.status === 200) {
            this.loading = false
            this.$toasted.show(response.data, {
              position: "bottom-center",
              duration: 5000
            });
            await this.getAdminList()
          }
        } catch (error) {
          this.loading = false
        }
      }
    },
    async editAdmin(payload) {
      let confirmTitle = "Confirm Update";
      let confirmText = "Please confirm to Update Admin";
      if (await this.$refs.confirm.open(confirmTitle, confirmText)) {
        this.loading = true
        try {
          payload['mobile'] = payload.mobile.toString()
          const response = await axios.patch('/router/editAdmins', payload)
          console.log(response)
          this.loading = false
          if (response.status === 200) {
            this.loading = false
            this.$toasted.show(response.data, {
              position: "bottom-center",
              duration: 5000
            });
            await this.getAdminList()
          }
        } catch (error) {
          this.loading = false
        }
      }
    },
    async getAdminList() {
      const payload = {
        search: this.search
      }
      const response = await axios.post('router/listAdmins', payload)
      console.log(response)
      this.adminList = response.data
      this.onClear()
    },
    onAdminClick(admin) {
      this.status = 'update'
      this.admin = admin
    },
    onClear() {
      this.admin = {
        name: null,
        mobile: null,
        email: null,
      }
      this.status = 'insert'
    },
  }
}
</script>

<style scoped lang="scss">
.admin-container {
  background-color: white;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  //height: calc(100vh - 120px);
  margin-top: 20px;

  .admin-add-row {
    display: flex;
    flex-direction: row-reverse;

    button {
      background: black;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 5px;
      padding: 8px 15px;
      font-size: 15px;
      width: 100px;
      border-radius: 5px;
      font-weight: bold;
    }
  }

  .admin-content {
    display: flex;
    width: 100%;
  }
}

</style>