<template>
  <div class="driver-insert-modal">
    <!--    <div class="clear-insert-modal" v-if="driverId !== ''">-->
    <!--      <v-btn color="#e38484">close</v-btn>-->
    <!--    </div>-->
    <v-card elevation="3" class="basic-details-modal">
      <span class="header-modal">Basic Details</span>
      <div class="text-field-modal">
        <div class="text-field-blk">
          <v-text-field
              dense
              outlined
              label="Name"
              placeholder="Name"
              v-model="name"
              :error-messages="nameError"
              @blur="$v.name.$touch()"
              @input="$v.name.$touch()"
          >

          </v-text-field>
        </div>
        <div class="text-field-blk">
          <v-text-field
              dense
              outlined
              label="Mobile Number"
              placeholder="Mobile Number"
              v-model="mobile"
              maxlength="10"
              :error-messages="mobileError"
              @blur="$v.mobile.$touch()"
              @input="$v.mobile.$touch()"
              @paste.prevent
          >

          </v-text-field>
        </div>
        <div class="text-field-blk">
          <v-text-field
              dense
              outlined
              label="Additional Contact"
              placeholder="Additional Contact"
              v-model="additionalContact"
              maxlength="10"
              :error-messages="additionalContactError"
              @blur="$v.additionalContact.$touch()"
              @input="$v.additionalContact.$touch()"
              @paste.prevent
          >

          </v-text-field>
        </div>
        <div class="text-field-blk">
          <v-text-field
              dense
              outlined
              label="Licence No"
              placeholder="Licence No"
              v-model="licenceNo"
              :error-messages="licenceNoError"
              @blur="$v.licenceNo.$touch()"
              @input="$v.licenceNo.$touch()"
          >

          </v-text-field>
        </div>
        <div class="date-input-blk">
          <div class="input-inner-blk">
            <v-menu
                v-model="dateModel"
                :nudge-right="40"
                :nudge-top="20"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="dateText"
                    outlined
                    label="Licence Expiry Date"
                    readonly
                    dense
                    v-on="on"
                    hide-details
                >
                </v-text-field>

              </template>
              <v-date-picker v-model="expiryDate" color="black"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
        <div class="button-blk">
          <v-btn color="#eea004" @click="submitDetails">{{ buttonName }}</v-btn>
          <v-btn color="#ed5e5e" @click="cancelSubmit">Cancel</v-btn>
        </div>

      </div>
    </v-card>
<!--    <v-card elevation="3" class="document-inset-modal" v-if="driverId !==''">-->
<!--      <DocumentInsert :driverId="driverId" :licenceNo="licenceNo" :expDate="expiryDate"-->
<!--                      :documents="documents"></DocumentInsert>-->
<!--    </v-card>-->

    <Loading v-if="loading"></Loading>
    <Confirm ref="confirm"></Confirm>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {minLength, numeric, required} from "vuelidate/lib/validators";
import * as moment from "moment";
import DocumentInsert from "@/components/driver-management/document-insert/DocumentInsert";
import axios from "@/axios/axios-global";
import Loading from "@/components/common/Loading";
import Confirm from "@/components/common/Confirm";

export default {
  name: "DriverInsert",
  components: {Confirm, Loading, DocumentInsert},
  props: {
    tabName: String,
    driverDetails: Object,
    editStatus: Boolean,
  },
  mixins: [validationMixin],
  validations() {
    return {
      name: {required},
      mobile: {required, minLength: minLength(10), numeric},
      licenceNo: {required},
      additionalContact: {required, minLength: minLength(10), numeric},
    }
  },
  watch: {
    tabName() {
      if (this.editStatus === true) {
        console.log(this.expiryDate)
        this.name = this.driverDetails.name
        this.mobile = this.driverDetails.mobile
        this.additionalContact = this.driverDetails.details.contactno
        this.licenceNo = this.driverDetails.document.licensefront.no
        this.expiryDate = moment(this.driverDetails.document.licensefront.expdate).format('YYYY-MM-DD')
        this.buttonName = 'edit'
        this.driverId = this.driverDetails.token
        this.documents = this.driverDetails.document

      } else {
        this.name = ''
        this.mobile = ''
        this.additionalContact = ''
        this.licenceNo = ''
        this.buttonName = 'submit'
        this.expiryDate = moment().add(2, 'years').format('YYYY-MM-DD')
        this.$v.$reset()
        this.driverId = ''
        console.log(this.expiryDate)
      }
    },
  },
  mounted() {
    console.log(this.driverDetails)
    if (this.editStatus === true) {
      this.name = this.driverDetails.name
      this.mobile = this.driverDetails.mobile
      this.additionalContact = this.driverDetails.details.contactno
      this.licenceNo = this.driverDetails.document.licensefront.no
      this.expiryDate = moment(this.driverDetails.document.licensefront.expdate).format('YYYY-MM-DD')
      this.buttonName = 'edit'
      this.driverId = this.driverDetails.token
      this.documents = this.driverDetails.document
    } else {
      this.name = ''
      this.mobile = ''
      this.additionalContact = ''
      this.licenceNo = ''
      this.expiryDate = moment().add(2, 'years').format('YYYY-MM-DD')
      this.$v.$reset()
      this.buttonName = 'submit'
      this.driverId = ''
    }
  },

  data: () => {
    return {
      name: '',
      mobile: '',
      additionalContact: '',
      licenceNo: '',
      expiryDate: moment().add(2, 'years').format('YYYY-MM-DD'),

      dateModel: false,

      buttonName: 'submit',

      loading: false,

      driverId: '',
      documents: '',

    }
  },
  methods: {
    async submitDetails() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        console.log('sample2');
      } else {
        if (this.buttonName === 'submit') {
          let driverArray = []
          const payload = {
            name: this.name,
            mobile: this.mobile,
            additionalContact: this.additionalContact,
            licensefront: {
              no: this.licenceNo,
              expdate: moment(this.expiryDate).format('YYYYMMDD')
            }
          }
          driverArray.push(payload)
          let confirmTitle = "Confirm Add";
          let confirmText = "Please confirm to Add the driver";
          if (await this.$refs.confirm.open(confirmTitle, confirmText)) {
            this.loading = true
            try {
              const response = await axios.post('/router/insertDriver', {driver: driverArray})
              console.log(response)
              if (response.status === 200) {
                this.loading = false
                this.$toasted.show(response.data.message, {
                  position: "bottom-center",
                  duration: 4000
                });
                // console.log(response.data.id[0].id)
                // console.log(this.name)
                // console.log(this.mobile)

                this.$emit('insert-driver', {
                  id: response.data.id[0].id,
                  name: this.name,
                  mobile: this.mobile,
                  token: response.data.id[0].token,
                  image: 'https://taski2020.s3.ap-south-1.amazonaws.com/noimage/noimage.png',
                })
                // this.buttonName = 'edit'
              } else if (response.status === 206) {
                this.loading = false
                this.$toasted.show(response.data, {
                  position: "bottom-center",
                  duration: 4000
                });
              }
            } catch (error) {
              this.loading = false
              this.$toasted.show(error.response, {
                position: "bottom-center",
                duration: 4000
              });
            }
          }
        } else if (this.buttonName === 'edit') {
          let confirmTitle = "Confirm Edit";
          let confirmText = "Please confirm to Edit the driver";
          if (await this.$refs.confirm.open(confirmTitle, confirmText)) {
            this.loading = true
            try {
              const payload = {
                id: this.driverDetails.token,
                name: this.name,
                mobile: this.mobile,
                additionalContact: this.additionalContact,
                licensefront: {
                  no: this.licenceNo,
                  expdate: moment(this.expiryDate).format('YYYYMMDD')
                }
              }
              const response = await axios.patch('/router/driverUpdate', payload)
              if (response.status === 200) {
                this.loading = false
                this.$toasted.show(response.data, {
                  position: "bottom-center",
                  duration: 4000
                });
                this.$emit('edit-driver', {id: this.driverDetails.id, name: this.name, mobile: this.mobile})
              } else if (response.status === 206) {
                this.loading = false
                this.$toasted.show(response.data, {
                  position: "bottom-center",
                  duration: 4000
                });
              }
            } catch (error) {
              this.loading = false
              this.$toasted.show(error.response, {
                position: "bottom-center",
                duration: 4000
              });
            }
          }
        }
      }
    },
    cancelSubmit() {
      this.name = ''
      this.mobile = ''
      this.additionalContact = ''
      this.licenceNo = ''
      this.expiryDate = moment().add(2, 'years').format('YYYY-MM-DD')
      this.$v.$reset()
      this.$emit('cancel-insert')
    }
  },
  computed: {
    nameError() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push('Name is required.')
      return errors
    },
    mobileError() {
      const errors = []
      if (!this.$v.mobile.$dirty) return errors
      !this.$v.mobile.required && errors.push('Number is required.')
      !this.$v.mobile.minLength && errors.push('Enter a valid Number')
      !this.$v.mobile.numeric && errors.push('Enter a valid Number')
      return errors
    },
    additionalContactError() {
      const errors = []
      if (!this.$v.additionalContact.$dirty) return errors
      !this.$v.additionalContact.required && errors.push('Number is required.')
      !this.$v.additionalContact.minLength && errors.push('Enter a valid Number')
      !this.$v.additionalContact.numeric && errors.push('Enter a valid Number')
      return errors
    },
    licenceNoError() {
      const errors = []
      if (!this.$v.licenceNo.$dirty) return errors
      !this.$v.licenceNo.required && errors.push('Licence No is required.')
      return errors
    },
    dateText() {
      return moment(this.expiryDate).format('DD-MM-YYYY')
    },
  }
}
</script>

<style scoped lang="scss">
@import "src/components/driver-management/driver-insert/driverInsert";
</style>