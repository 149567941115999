<template>
  <div class="vehicle-details-modal" v-if="vehicleDetails.details">
    <div class="vehicle-no-blk text-center">
      <span>{{vehicleDetails.vehicleno}}</span>
    </div>
    <div class="manufacturer-blk text-center">
      <span>{{vehicleDetails.details.manufacturer.name}}</span>
    </div>
    <div class="manufacturer-blk text-center">
      <span>{{vehicleDetails.details.model.name}}</span>
    </div>
    <div class="manufacturer-blk text-center">
      <span>{{vehicleDetails.category.name}}</span>
    </div>
    <div class="manufacturer-blk text-center">
      <span>{{vehicleDetails.details.color.name}}</span>
    </div>
    <div class="manufacturer-blk text-center">
      <span>{{vehicleDetails.details.year}}</span>
    </div>
    <div class="manufacturer-blk text-center" v-for="(data,index) in vehicleDetails.details.fuel" :key="index">
      <span>{{data.name}}</span>
    </div>
    <div class="driver-blk text-center" v-if="vehicleDetails.drivers.length > 0">
      <div v-for="(driver,index1) in vehicleDetails.drivers" :key="driver.id">
        <span>{{driver.name}}<b v-if="index1 !== vehicleDetails.drivers.length - 1">,</b></span>
      </div>
    </div>
    <div v-if="vehicleDetails.drivers.length === 0" class="text-center no-driver-blk">
      <span>No driver Assigned</span>
    </div>
    <div class="button-blk">
      <v-btn color="#eea004" @click="editVehicle">Edit</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "VehicleDetails",
  props: {
    vehicleDetails: Object,
  },
  methods: {
    editVehicle() {
      this.$emit('edit-vehicle',this.vehicleDetails)
    }
  }
}
</script>

<style scoped lang="scss">
@import "./vehicleDetails";
</style>