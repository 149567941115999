<template>
  <v-card  elevation="3" class="user-calculation-modal" v-if="userFare">
    <span class="user-header-blk">Corporate</span>
    <div class="user-calculation-content-modal">
      <div class="content-modal" v-for="(data,index) in userFare" :key="index">
        <strong class="header-column" v-if="data.data.length < 3">{{data.name}}</strong>
        <div class="detailed-column" v-if="data.data.length > 2">
          <span class="detailed-blk">{{data.name}}</span>
          <span class="detailed-blk">{{data.data[0]}}</span>
          <span class="detailed-blk">{{data.data[1]}}</span>
        </div>
        <span class="content-column"  v-if="data.data.length < 3">{{data.data[0]}}</span>
        <span class="content-column" v-if="data.data.length > 2">{{data.data[2]}}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "UserCalculation",
  props: {
    userFare : Array
  },
  methods: {
    getTimes(time) {
      let h = Math.floor(time / 60);
      let m = time % 60;
      h = h < 10 ? '' + h : h;
      m = m < 10 ? '' + m : m;
      return `${h} hrs ${m.toString().substring(0, 2)} mins`;
    }
  }
}
</script>

<style scoped lang="scss">
.user-calculation-modal {
  padding: 10px;
  border: 1px solid gray;
  margin-top: 20px;
  border-radius: 0;
  .user-header-blk {
    font-weight: bold;
    font-size: 17px;
    color: #fa7815;

  }
  .user-calculation-content-modal {
    width: 40%;
    border: 1px solid gray;
    border-bottom: none;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    .content-modal {
      display: flex;
      border-bottom: 1px solid gray;
      .header-column {
        width: 65%;
        border-right: 1px solid gray;
        padding: 5px;
        font-size: 13px;
      }
      .content-column {
        width: 35%;
        padding: 5px;
        text-align: right;
      }
      .total-column {
        font-weight: bold;
        font-size: 14px;
      }
      .detailed-column {
        width: 65%;
        border-right: 1px solid gray;
        font-size: 13px;
        display: flex;
        font-weight: bold;
        .detailed-blk {
          width: 33%;
          padding: 5px;
          border-right: 1px solid gray;
        }
        :last-child {
          width: 34%;
          border: none;
        }
      }
      .total-column-header {
        font-weight: bold;
        font-size: 14px;
        color: #fa7815;
      }
    }
  }
}
</style>