<template>
  <v-card class="fare-modal">
    <div class="fare-content-modal">
      <div class="fare-list-modal">
        <FareList :branchList="branchList" @select-branch="selectBranch"></FareList>
      </div>

      <div class="fare-details-modal">
        <FareDetails :branchId="branchId"></FareDetails>
      </div>
    </div>
    <Loading v-if="loading"></Loading>
  </v-card>
</template>

<script>
import FareList from "@/components/fares/fare-list/FareList";
import axios from "@/axios/axios-global";
import FareDetails from "@/components/fares/fare-details/FareDetails";
import Loading from "@/components/common/Loading";

export default {
  name: "Fare",
  components: {Loading, FareDetails, FareList},
  data: () => {
    return {
      branchList: [],

      branchId: 0,

      loading: false,
    }
  },
  async mounted() {
    await this.getBranchList()
  },
  methods: {
    async getBranchList() {
      this.loading = true
      const response = await axios.get('/router/branchList')
      console.log(response)
      this.loading = false
      this.branchList = response.data
      if(this.branchList.length > 0) {
        this.branchId = this.branchList[0].id
      }
    },
    selectBranch(data) {
      this.branchId = data
    }
  }
}
</script>

<style scoped lang="scss">
.fare-modal {
  margin: 10px 20px;
  background-color: white;
  height: calc(100vh - 90px);
  border-radius: 5px;
  padding: 10px;
  font-family: 'Muli',sans-serif;
  .fare-content-modal {
    display: flex;
    justify-content: space-between;
    .fare-list-modal {
      width: 25%;
    }
    .fare-details-modal {
      width: 73%;

    }
  }
}
</style>