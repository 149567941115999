<template>
  <div class="main-inner-blk">
    <v-card class="driver-filter-blk" elevation="3">
      <Filters :placeHolder="'Search Driver Name/Number'" @get-filter="getFilters"></Filters>
    </v-card>

    <div class="driver-modal">
      <div class="driver-list-modal">
        <DriverDataList :driverList="driverList" @select-driver="selectDriver" @add-driver="addDriver" :id="driverId" @insert-driver="insertDriver"></DriverDataList>
      </div>
      <div class="driver-content-modal">
        <v-tabs
            background-color="black"
            color="#facc15"
            slider-color="#facc15"
            dark
            v-model="tabs"
        >
          <v-tab href="#profile-tab" :disabled="tabs === 'insert-tab'">Details</v-tab>
          <v-tab href="#insert-tab" v-if="tabs === 'insert-tab'">{{ buttonName }} Driver</v-tab>
          <v-tab href="#document-tab" :disabled="tabs === 'insert-tab'">Documents</v-tab>

          <v-tabs-items v-model="tabs">
            <v-tab-item id="insert-tab">
              <DriverInsert @insert-driver="insertDriver" @cancel-insert="cancelInsert" :tabName="tabs" :driverDetails="driverDetails" :editStatus="editStatus" @edit-driver="driverEdit"></DriverInsert>
            </v-tab-item>

            <v-tab-item id="profile-tab">
              <DriverProfile :driverId="driverId" :driverName="driverName" :driverToken="driverToken"
                             @assign-vehicle="assignVehicle" @emit-driver-details="editDriver"></DriverProfile>
            </v-tab-item>
            <v-tab-item id="document-tab">
              <DocumentInsert :driverId="driverId" :driverToken="driverToken"></DocumentInsert>
            </v-tab-item>
          </v-tabs-items>

        </v-tabs>
      </div>

    </div>
    <Loading v-if="loading"></Loading>
  </div>
</template>

<script>
import Filters from "@/components/common/filter/Filters";
import DriverDataList from "@/components/driver-management/driver-data-list/DriverDataList";
import DriverInsert from "@/components/driver-management/driver-insert/DriverInsert";
import axios from "@/axios/axios-global";
import DriverProfile from "@/components/driver-management/driver-profile/DriverProfile";
import Loading from "@/components/common/Loading";
import DocumentInsert from "@/components/driver-management/document-insert/DocumentInsert";

export default {
  name: "DriverDashboard",
  components: {DocumentInsert, Loading, DriverProfile, DriverInsert, DriverDataList, Filters},
  data: () => {
    return {
      driverList: [],
      tabs: 'profile-tab',

      driverId: 0,
      driverName: '',
      driverToken: '',

      driverDetails: {},
      editStatus: false,

      loading: false,

      buttonName: 'ADD',

      filters: ''
    }
  },
  async mounted() {
    await this.getDriverList({searchValue: ''})
  },
  methods: {
    getFilters(data) {
      this.filters = data
      this.getDriverList(this.filters)
    },
    async getDriverList(data) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(async () => {
        this.loading = true
        const response = await axios.post('/router/driverList', {search: data.searchValue})
        console.log(response)
        this.loading = false
        this.driverList = response.data
        if (this.driverList.length > 0) {
          this.driverId = this.driverList[0].id
          this.driverName = this.driverList[0].name
          this.driverToken = this.driverList[0].token
        } else {
          this.driverId = 0
          this.driverName = ''
          this.driverToken = ''
        }
      },300)
    },
    insertDriver(data) {
      console.log(data)
      this.driverList.unshift(data)
      this.tabs = 'profile-tab'
      this.driverId = data.id
      this.driverName = data.name
      this.driverToken = data.token
    },
    selectDriver(data) {
      this.driverId = data.id
      this.driverName = data.name
      this.driverToken = data.token
      this.tabs = 'profile-tab'
    },
    addDriver() {
      this.tabs = 'insert-tab'
      this.editStatus = false
      if(this.buttonName === 'Edit') {
        this.tabs = 'profile-tab'
        setTimeout(() => {
          this.tabs = 'insert-tab'
        },0)
        this.buttonName = 'ADD'
      }

    },
    cancelInsert() {
      this.tabs = 'profile-tab'
    },
    assignVehicle(data) {
      this.driverId = data
    },
    editDriver(data) {
      this.tabs = 'insert-tab'
      this.driverDetails = data
      this.editStatus = true
      this.buttonName = 'Edit'
    },
    driverEdit(data) {
      const index = this.driverList.findIndex(item => item.id === data.id)
      if(index !== -1) {
        this.driverList[index].name = data.name
        this.driverList[index].mobile = data.mobile
      }
      this.driverId = 0
      setTimeout(() => {
        this.driverId = data.id
      },200)
      this.tabs = 'profile-tab'
    },
  }
}
</script>

<style scoped lang="scss">
@import "./driverDashboard";
</style>