<template>
  <div class="cab-profile-modal">
    <div class="cab-profile-tab--blk" v-if="vehicleId !== 0">
      <div class="cab-details-tab-modal">
        <v-card class="cab-profile-blk" elevation="2">
          <VehicleDetails :vehicleDetails="vehicleDetails" @edit-vehicle="editVehicleDetails"></VehicleDetails>
        </v-card>
      </div>
    </div>
    <div v-else class="no-vehicle-profile">
      <span>No Data Available</span>
    </div>
    <Loading v-if="loading"></Loading>
  </div>
</template>

<script>
import axios from "@/axios/axios-global";
import VehicleDetails from "@/components/vehicle-management/vehicle-details/VehicleDetails";
import Loading from "@/components/common/Loading";

export default {
  name: "VehicleProfile",
  components: {Loading, VehicleDetails},
  props: {
    vehicleId: Number,
  },
  watch: {
    vehicleId() {
      this.getVehicleDetails()
    }
  },
  mounted() {

  },
  data: () => {
    return {
      vehicleDetails : {},

      loading: false,
    }
  },
  methods: {
    async getVehicleDetails() {
      if(this.vehicleId !==0) {
      this.loading = true
      const response = await axios.post('/router/vehicleDetailedData',{vehicleId:this.vehicleId})
      console.log(response)
      this.loading = false
      this.vehicleDetails = response.data
      }
    },
    editVehicleDetails(data) {
      this.$emit('edit-vehicle-data',data)
    }
  }
}
</script>

<style scoped lang="scss">
@import "./vehicleProfile";
</style>