<template>
  <div class="ets-details-container">
    <div class="header-row">
      <h4>{{ trip.etsDetails.serviceType }}</h4>
      <v-chip :color="trip.status.colour"  outlined dense>{{ trip.status.status }}</v-chip>
    </div>
    <div class="employees-block">
      <v-card class="employee-list" v-for="(employee,index) in trip.etsDetails.employees" :key="index" elevation="2">
        <div class="name-row">
          <span>{{employee.name}}</span>
          <span v-if="employee.tripstatus === -1" class="no-show-block">Didn't Show</span>
          <span v-if="employee.tripstatus === 1" class="pickup-block">{{trip.etsDetails.serviceType === 'DropOff' ? 'Dropped' : 'Picked Up'}} </span>
        </div>
        <span>{{employee.mobile}}</span>
        <span>{{employee.address}}</span>
        <span class="otp-block">{{employee.pickUpDate}}</span>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "ETSDetails",
  props: {
    trip: [Object,String],
  },
}
</script>

<style scoped lang="scss">
.ets-details-container {
  width: 100%;
  margin: 30px 0;
  font-family: "Muli", sans-serif;
  //border: 1px solid #cfcfcf;
  display: flex;
  flex-direction: column;

  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid #cfcfcf;
    padding: 8px;
    h4 {
      font-family: "Muli", sans-serif;
      font-size: 1.2em;
      color: #fa7815;
      text-transform: uppercase;
    }
  }
  .employees-block {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    padding: 10px 0;
    .employee-list {
      width: 49%;
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: flex-start;
      padding: 10px;
      background: #fcfcfc;
      border: 1px solid #cfcfcf;
      //border-right: 1px solid #cfcfcf;
      //border-bottom: 1px solid #cfcfcf;
      span {
        text-align: start;
      }
      .name-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #fa7815;

        .no-show-block {
          font-weight: bold;
          color: red;
        }
        .pickup-block {
          font-weight: bold;
          color: green;
        }
      }
      .otp-block {
        font-weight: bold;
        color: grey;
        margin: 5px 0;
      }
      //b {
      //  font-weight: 600;
      //  color: #ad1457;
      //}
      //&:nth-child(even) {
      //  border-right: none;
      //}
    }
  }
}
</style>