// const API_KEY = `AIzaSyAvbyv2_MDv2NGQXRg9OHfUDWsTHtUYeFY`; //- Payment enabled
// const API_KEY = `AIzaSyBALcxx8mwXMJa79b1BhaSrS1mVAeDTLCw`; //- Payment enabled
// const API_KEY = `AIzaSyCLKBzkItRNBnQHwxugZq1LDYjQGfQHJnc`; //- Info@taski.in
// const API_KEY = `AIzaSyA7rJ4upVPYMQjNVohB83D7E5TSPiU7iOc`; //- taskiemp3
// const API_KEY = `AIzaSyA2kTLR-dUGSE_w6CeTQSiGjEZlvhciN9A`; //- Real
// const API_KEY = `AIzaSyAwwrkPYJblpWCUF9PhrKyRQMIPoqV1_RY`; //- taskiemp3 new
// const API_KEY = `AIzaSyCFtFfo-aOCXIp9l9bB7b6ggNXi8XMSiys`; //- Info@taski.in new restricted
// const API_KEY = `AIzaSyCLKBzkItRNBnQHwxugZq1LDYjQGfQHJnc`; //- Info@taski.in new 2 unlimited
// const API_KEY = `AIzaSyDIE64ScXYNGK1jxCRgqzwI6spjDpiKbLo`; //- chnovo4@gmail.com
// const API_KEY = `AIzaSyBiJ1gdtZGlpJnn5P92_efI8rPNUU-gRR8`; //- taskiemp17@gmail.com
// const API_KEY = `AIzaSyCTQ1CobRVycwRnDKPVeHkH9n7YsVXz4Z4`; //- taskiemp6@gmail.com
// const API_KEY = `AIzaSyDF6PIMlHgmHScTQfX5kBe93AWGRLPz1PE`; //- taskiemp13@gmail.com - server
// const API_KEY = `AIzaSyBtClZcvahy4yj510bOJc3P6_jHjXzPpK8`; //- taskiemp13@gmail.com - local
// const API_KEY = `AIzaSyBtxChej0tDcDRFhr6fD72AWjGexdSNZ6c`; //- chnovo5@gmail.com - server
// const API_KEY = `AIzaSyDLnAf0InkEnVlveiGasEfZ-wZHH-6_pFc`; //- chnovo5@gmail.com - local
// const API_KEY = `AIzaSyDpcSy0cekDhBRy6e81ocfXEtGvPg8k0fQ`;  //- unknown
// const API_KEY = `AIzaSyAyEcz7uGfPDCsedwtvpmLB3spXG1YRrEk`; //- chnovo2@taski.in
// const API_KEY = `AIzaSyBVznmFMGVc22qyp25eFKddu-uIE6jMLVc`; //- chnovo41@gmail.com
// const API_KEY = `AIzaSyBi3-EhasiteamqXHsNsLbLPaqDw5XLJ3o`; //- chnovo41@gmail.com
// const API_KEY = `AIzaSyDH5OWSkfHPTLasU-t5EMGbVU_6AshqDDI`; //- chnovo55@gmail.com
const API_KEY = `AIzaSyCS7qxCoQs6rq3BfZtZDYjLPtJH3ew-fKk`; //- chnovo55@gmail.com
const CALLBACK_NAME = `gmapsCallback`;

let initialized = !!window.google;
let resolveInitPromise;
let rejectInitPromise;
// This promise handles the initialization
// status of the google maps script.
const initPromise = new Promise((resolve, reject) => {
    resolveInitPromise = resolve;
    rejectInitPromise = reject;
});

export default function init() {
    // If Google Maps already is initialized
    // the `initPromise` should be resolved
    // eventually.
    if (initialized) return initPromise;

    initialized = true;
    // The callback function is called by
    // the Google Maps script if it is
    // successfully loaded.
    window[CALLBACK_NAME] = () => resolveInitPromise(window.google);

    // We inject a new script tag into
    // the `<head>` of our HTML to load
    // the Google Maps script.
    const script = document.createElement(`script`);
    script.async = true;
    script.defer = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=${CALLBACK_NAME}&libraries=drawing,geometry,places`;
    // script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&sensor=false&libraries=drawing`;
    script.onerror = rejectInitPromise;
    document.querySelector(`head`).appendChild(script);

    return initPromise;
}
