<template>
  <div class="trip-details-modal">
    <v-card elevation="3" class="trip-details-card-modal">
      <div class="trip-id-column details-column">
        <span class="header-blk"># ID</span>
        <strong>{{ tripDetails.id }}</strong>
        <b>{{ tripDetails.token }}</b>
      </div>
      <div class="user-column details-column">
        <span class="header-blk">Customer</span>
        <strong>{{ tripDetails.name }}</strong>
      </div>
      <div class="number-column details-column">
        <span class="header-blk">Number</span>
        <strong>{{ tripDetails.mobile }}</strong>
      </div>
      <div class="cab-type-column details-column" v-if="tripDetails.category">
        <span class="header-blk">Cab Type</span>
        <span>{{ tripDetails.category.name }}</span>
      </div>
      <div class="trip-hire-column details-column" v-if="tripDetails.tripType">
        <span class="header-blk">Trip Type</span>
        <span v-if="!enableETS">{{ tripDetails.tripType.name }}</span>
        <span class="trip-info-field-data" v-else-if="enableETS&& tripDetails.etsDetails">{{tripDetails.etsDetails.serviceType}}</span>
      </div>
      <div class="date-column details-column">
        <span class="header-blk">Trip Date</span>
        <span>{{ getTimeDate(tripDetails.date) }}</span>
      </div>
      <div class="from-location-column details-column">
        <span class="header-blk">From</span>
        <span>{{ tripDetails.source }}</span>
      </div>
      <div class="to-location-column details-column">
        <span class="header-blk">To</span>
        <span>{{ tripDetails.destination }}</span>
      </div>
    </v-card>

    <v-card elevation="3" class="customer-details-modal">
      <div class="branch-column" v-if="tripDetails.branch">
        <span class="user-header-blk">Branch</span>
        <span>{{ tripDetails.branch.name }}</span>
      </div>
      <div class="branch-column" v-if="tripDetails.city">
        <span class="user-header-blk">City</span>
        <span>{{ tripDetails.city.name }}</span>
      </div>
      <div class="branch-column" v-if="tripDetails.department">
        <span class="user-header-blk">Department</span>
        <span>{{ tripDetails.department.name }}</span>
      </div>
      <div class="branch-column" v-if="tripDetails.vendor">
        <span class="user-header-blk">Vendor</span>
        <span>{{ tripDetails.vendor.name }}</span>
      </div>
      <div class="branch-column" v-if="tripDetails.driver">
        <span class="user-header-blk">Driver</span>
        <span>{{ tripDetails.driver.name }} - {{ tripDetails.driver.mobile }}</span>
      </div>
      <div class="branch-column" v-if="tripDetails.vehicle">
        <span class="user-header-blk">Car</span>
        <span>{{ tripDetails.vehicle.vehicleno }} - {{ tripDetails.vehicle.info }}</span>
      </div>
      <div class="branch-column">
        <span class="user-header-blk">Booking Time</span>
        <span>{{ getTimeDate(tripDetails.booking_date) }}</span>
      </div>
      <div class="branch-column">
        <span class="user-header-blk">Arrived Time</span>
        <!--        <span>{{ getTimeDate(tripDetails.arrived.time) }}</span>-->
      </div>
    </v-card>

<!--    <v-card elevation="3" class="leads-modal" v-if="tripDetails.leads">-->
<!--      <span class="leads-header-blk">Leads Details</span>-->
<!--      <div class="leads-content-modal">-->
<!--        <div class="leads-blk" v-for="(data,index) in tripDetails.leads" :key="index">-->
<!--          <span>{{ data.comment }}</span>-->
<!--          <span>{{ getTimeDate(data.time) }}</span>-->
<!--          <span>{{ data.address }}</span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </v-card>-->

    <UserDriverData :userReading="tripDetails.userreading" :driverReading="tripDetails.driverreading"></UserDriverData>

    <UserCalculation :userFare="tripDetails.userfare"></UserCalculation>

    <TripTollView :toll="tripDetails.toll" v-if="tripDetails.toll"></TripTollView>

    <ETSDetails :trip="tripDetails" v-if="tripDetails.etsDetails"></ETSDetails>

    <Loading v-if="loading"></Loading>
  </div>
</template>

<script>
import * as moment from 'moment'
import UserDriverData from "@/components/Trips/trip-details/user-driver-data/UserDriverData";
import UserCalculation from "@/components/Trips/trip-details/user-driver-data/UserCalculation";
import axios from "@/axios/axios-global";
import Loading from "@/components/common/Loading";
import TripTollView from "@/components/Trips/trip-details/TripTollView";
import ETSDetails from "@/components/Trips/ets/ETSDetails";

export default {
  name: "TripDetails",
  components: {ETSDetails, TripTollView, Loading, UserCalculation, UserDriverData},
  props: {
    tripId: Number
  },
  async mounted() {
    this.enableETS = JSON.parse(localStorage.getItem('enableETS'))
    await this.getTripDetails()
  },
  data: () => {
    return {
      from: '16-621/13/5, National Highway 219, Seshappa Thota, Madanapalle, Andhra Pradesh 517325, India',

      tripDetails: '',
      loading: false,
      enableETS: ''
    }
  },
  methods: {
    async getTripDetails() {
      this.loading = true
      const response = await axios.post('/router/tripDetails', {tripId: this.tripId})
      console.log(response)
      if (response.status === 200) {
        this.loading = false
        this.tripDetails = response.data
      }
    },
    getTimeDate(data) {
      return moment(data).format('DD-MM-YYYY LT')
    }
  }
}
</script>

<style scoped lang="scss">
@import "./tripDetails";
</style>